const Spike6 = ({ className }: { className?: string }) => (
  <div className={className}>
    <svg
      width="118"
      height="98"
      viewBox="0 0 118 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M101.862 76.1934H15.9257C7.58113 76.1934 0.753784 69.366 0.753784 61.0215V21.739C0.753784 13.3945 7.58113 6.56714 15.9257 6.56714H101.874C110.219 6.56714 117.046 13.3945 117.046 21.739V61.0341C117.034 69.366 110.206 76.1934 101.862 76.1934Z"
        fill="#B2D235"
      />
      <path
        d="M28.0126 67.0902L27.4942 97.965L75.5258 65.1432"
        fill="#B2D235"
      />
      <path
        d="M21.4128 37.4419L47.0407 63.0697L108.145 1.96503"
        stroke="black"
        strokeWidth="3.16081"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
);

export default Spike6;
