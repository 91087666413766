import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import {
  setHuddleDay,
  setHuddleId,
  setHuddleTime,
  startOrJoinHuddle,
  setTopicArticle,
} from "../../reducers/huddleSlice";
import { fetchUserHuddles } from "../../reducers/userHuddlesSlice";
import { getCurrentDateTime } from "@/app/_utils/helper";
import Image from "next/image";
import { Button } from "../buttons/button";
import { Input } from "../forms/input";
import LocationSearchInput from "../search/LocationSearchInput";
import { CheckOutlined } from "@ant-design/icons";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/app/_components/ui/dialog";
import { cn } from "@/app/_utils/ui";
import ArticleSearch from "../search/ArticleSearch";
import { AppDispatch } from "../../reducers/store";
import RocketIcon from "../graphics/RocketIcon";
import { getFormattedDate } from "@/app/_utils/formatter";
import { setSelectedLocation } from "../../reducers/locationDashboardSlice";
import { TOKEN_RESPONSE_CONFIG } from "../../reducers/loginSlice";

const StartHuddle = ({
  open,
  setOpen,
  children,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  children?: React.ReactNode;
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [articleMode, setArticleMode] = useState<"daily" | "pick">("daily");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const dailyArticleId = useSelector(
    (state: RootState) => state.dailyArticle.article.id,
  );
  const { selectedLocation } = useSelector(
    (state: RootState) => state.locationDashboard,
  );
  const [changeDetails, setChangeDetails] = useState(false);
  const articles = useSelector((state: RootState) => state.articles);
  const dailyArticle = articles.articles[dailyArticleId];
  const topicArticle = useSelector(
    (state: RootState) => state.huddleGroups.topicArticle,
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (open) {
      const { currentDateString, currentTimeString } = getCurrentDateTime();
      if (selectedLocation?.name.startsWith("VQ -")) {
        dispatch(setSelectedLocation({ ...selectedLocation, name: "VQ" }));
      }
      setSelectedDate(currentDateString);
      setSelectedTime(currentTimeString);
    }
  }, [open]);

  useEffect(() => {
    if (dispatch && dailyArticle) {
      dispatch(setTopicArticle(dailyArticle));
    }
  }, [dailyArticle, dispatch]);

  if (!accessToken) return null;

  const onSaveChanges = async () => {
    if (selectedLocation) {
      setIsSaving(true);

      const dateTime = new Date(`${selectedDate}T${selectedTime}`);
      const timeDisplay = `${dateTime.getHours() % 12 || 12}:${
        dateTime.getMinutes() < 10 ? "0" : ""
      }${dateTime.getMinutes()}${dateTime.getHours() >= 12 ? "pm" : "am"}`;

      const huddleTime = `${selectedDate}T${selectedTime}:00`; // Ensures we keep local time
      const formattedHuddleDay = `${dateTime.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })} @ ${timeDisplay}`;

      dispatch(setHuddleDay(formattedHuddleDay));

      const body = {
        huddleTime,
        location: selectedLocation.name,
      };

      // Reset and save the huddle
      dispatch(setHuddleId(null));
      dispatch(setHuddleTime(huddleTime));

      dispatch(
        startOrJoinHuddle({
          articleId: topicArticle.id,
          body,
          accessToken,
          type: "start",
        }),
      )
        .unwrap()
        .then(async () => {
          setIsSaving(false);
          setOpen(false);
          await dispatch(fetchUserHuddles(accessToken));
        })
        .catch((error: unknown) => {
          setIsSaving(false);
          if (
            typeof error === "object" &&
            error !== null &&
            "message" in error &&
            error.message === "Unauthorized"
          ) {
            localStorage.removeItem(TOKEN_RESPONSE_CONFIG);
            window.location.reload();
          } else {
            const errorMessage =
              typeof error === "string"
                ? error
                : (error as { message?: string }).message;
            setErrorMessage(
              `Error: ${
                errorMessage ||
                "An error occurred while starting the huddle. Please try again."
              }`,
            );
          }
        });
    } else {
      alert("Please select a location");
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children ? (
          children
        ) : (
          <Button
            className="items-center h-[45px] w-[250px] md:w-[124px]"
            id="startHuddle"
          >
            <Image
              width={24}
              height={24}
              alt=""
              src="/assets/images/icons/huddle/start-icon.svg"
              className="mr-2"
            />{" "}
            <span className="pt-0.5">Start</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <div
          id="addHuddle"
          className="flex flex-col gap-4 items-center justify-center p-10 max-w-[540px] text-center"
        >
          <div className="w-[45px] h-[45px] flex justify-center items-center rounded-full bg-red-secondary">
            <RocketIcon className="relative sm:scale-[.875] top-px right-px" />
          </div>
          <div className="text-lg -mt-4">New Huddle</div>

          {changeDetails || !selectedLocation ? (
            <>
              <div className="w-full text-left">
                <label className="block text-neutral-700 text-sm mb-1">
                  <span className="text-red-600">*</span> Location:
                </label>
                <LocationSearchInput
                  additionalFilter={(item) => !item.name.includes("VQ -")}
                />
              </div>
              <div className="w-full text-left">
                <label className="block text-neutral-700 text-sm mb-1">
                  Time of Huddle:
                </label>
                <div className="grid grid-cols-2 gap-[7px]">
                  <Input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    className="block"
                  />
                  <Input
                    type="time"
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    className="block"
                  />
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className="text-xl font-semibold">
                {selectedLocation.name}
              </div>
              <div>{getFormattedDate(new Date())}</div>
              <button
                onClick={() => setChangeDetails(true)}
                className="underline text-sm text-blue-tint"
              >
                Change
              </button>
            </div>
          )}

          <div className="w-full">
            <label className="block text-red-secondary font-display uppercase font-semibold text-lg my-2">
              What’s Your Topic?
            </label>
            <div className="grid grid-cols-2 gap-2">
              <Button
                onClick={() => {
                  dispatch(setTopicArticle(dailyArticle));
                  setArticleMode("daily");
                }}
                variant={articleMode === "daily" ? "gold" : "outlineSquare"}
                className="w-full"
              >
                <CheckOutlined
                  className={cn(articleMode === "daily" ? "mr-1" : "hidden")}
                />{" "}
                Daily Article
              </Button>
              <Button
                onClick={() => setArticleMode("pick")}
                variant={articleMode === "pick" ? "gold" : "outlineSquare"}
                className="w-full"
              >
                <CheckOutlined
                  className={cn(articleMode === "pick" ? "mr-1" : "hidden")}
                />{" "}
                Pick an Article
              </Button>
            </div>
          </div>
          {articleMode === "pick" && (
            <div className="w-full z-20 relative">
              <ArticleSearch
                onSelectArticle={(article) => {
                  dispatch(
                    setTopicArticle({
                      id: article.articleId,
                      title: article.title,
                    }),
                  );
                }}
              />
            </div>
          )}
          <div className="w-full">
            <label className="block text-neutral-700 text-sm">Article</label>
            {topicArticle && (
              <div className="text-sm">{topicArticle.title}</div>
            )}
          </div>
          {errorMessage && (
            <div className="w-full text-red-600 text-sm">{errorMessage}</div>
          )}
          <div className="flex w-full justify-end mt-4 space-x-4">
            <Button
              disabled={isSaving}
              onClick={() => setOpen(false)}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={isSaving}
              id="saveHuddle"
              variant="primary"
              onClick={onSaveChanges}
            >
              Save Huddle
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StartHuddle;
